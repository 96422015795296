<template>
  <div>

    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <h2 class="mr-1">
            <strong>{{ textoConnect }}</strong>
          </h2>
        </div>
      </template>

      <b-row>
        <b-col cols="12">
          <b-card-actions
            v-if="operacao.p && operacao.p.dvp"
            title="Dashboard Pair"
            action-collapse
          >
            <b-row>

              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formatDateTimeUTC0(operacao.p.dvp.dataEntrada) }}
                  </h2>
                  <span>Data Entrada (UTC-0)</span>
                </div>
              </b-col>

              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ operacao.p.dvp.ativoY }}
                  </h2>
                  <span>Ativo Y</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ operacao.p.dvp.ativoX }}
                  </h2>
                  <span>Ativo X</span>
                </div>
              </b-col>

              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ operacao.p.dvp.direcaoY }}
                  </h2>
                  <span>Direção Y</span>
                </div>
              </b-col>

              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">

                    <template v-if="operacao.p.dvp.pnlLiquido!=null">
                      <div class="text-nowrap">
                        <span
                          :class="parseFloat(operacao.p.dvp.pnlLiquido) >= 0? 'text-success' : 'text-danger'"
                        >
                          {{ formataValor(operacao.p.dvp.pnlLiquido, 2, true) }}
                        </span>
                      </div>
                    </template>
                  </h2>
                  <span>Lucro Total</span>
                </div>
              </b-col>

              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(operacao.p.dvp.custo, 2, true) }}
                  </h2>
                  <span>Custo Total</span>
                </div>
              </b-col>
            </b-row>

          </b-card-actions>
          <b-card-actions
            v-if="operacao.p && operacao.p.dv"
            title="Dashboard"
            action-collapse
          >
            <b-row>

              <b-col
                lg="3"
                sm="6"
                class="mb-1"
              >
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formatDateTimeUTC0(operacao.p.dv.dataEntrada) }}
                  </h2>
                  <span>Data Entrada <small>(UTC-0)</small></span>
                </div>
              </b-col>

              <b-col
                class="mb-1"
              >
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ operacao.p.dv.ativo.toUpperCase() }}
                  </h2>
                  <span>Ativo</span>
                </div>
              </b-col>

              <b-col
                class="mb-1"
              >
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ operacao.p.dv.direcao }}
                  </h2>
                  <span>Direção</span>
                </div>
              </b-col>

              <b-col
                class="mb-1"
              >
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    <template v-if="operacao.p.dv.lucroLiquidoPercentual!=null">
                      <div class="text-nowrap">
                        <span
                          :class="parseFloat(operacao.p.dv.lucroLiquidoPercentual) >= 0? 'text-success' : 'text-danger'"
                        >
                          {{ formataValor(operacao.p.dv.lucroLiquidoPercentual, 2, true) }}
                        </span>
                      </div>
                    </template>
                  </h2>
                  <span>Lucro (%)</span>
                </div>
              </b-col>

              <b-col class="mb-1">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">

                    <template v-if="operacao.p.dv.pnlLiquido!=null">
                      <div class="text-nowrap">
                        <span
                          :class="parseFloat(operacao.p.dv.pnlLiquido) >= 0? 'text-success' : 'text-danger'"
                        >
                          {{ formataValor(operacao.p.dv.pnlLiquido, 2, true) }}
                        </span>
                      </div>
                    </template>
                  </h2>
                  <span>Lucro <small>(USDT)</small></span>
                </div>
              </b-col>

              <b-col class="mb-1">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(operacao.p.dv.custo, 2, true) }}
                  </h2>
                  <span>Custo <small>(USDT)</small></span>
                </div>
              </b-col>

            </b-row>

          </b-card-actions>
        </b-col>

        <b-col cols="12">
          <b-card-actions
            v-if="operacao.p && operacao.p.dv"
            title="Controle"
            action-collapse
          >

            <b-row>

              <b-col
                lg="3"
                sm="6"
                class="mb-1"
              >
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      @click.stop="confirmarEncerrarAgora(operacao.id)"
                    >
                      <feather-icon
                        icon="TargetIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Encerrar</span>
                    </b-button>
                  </h2>
                  <span>Encerrar operação</span>
                </div>
              </b-col>

              <b-col
                lg="3"
                sm="6"
                class="mb-1"
              >
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    <b-form-checkbox
                      :checked="!operacao.saidaAutomaticaDesativado"
                      switch
                      inline
                      @change="confirmarSaidaAutomaticaDesativado(operacao.id, !operacao.saidaAutomaticaDesativado)"
                    >
                      <strong>{{ operacao.saidaAutomaticaDesativado ? 'DESATIVADA' : 'ATIVADA' }}</strong>
                    </b-form-checkbox>
                  </h2>
                  <span>Saída automática</span>
                </div>
              </b-col>

              <b-col
                v-if="alvo"
                class="mb-1"
              >
                <div class="truncate">
                  <span class="d-flex align-items-center text-muted text-nowrap">
                    <h2 class="mb-25 font-weight-bolder">{{ alvo }}</h2>
                    <b-link
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.modal-alterar-alvo
                      class="ml-1"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="18"
                      />
                    </b-link>
                  </span>
                  <span>Alvo (% Saldo)</span>
                </div>
              </b-col>

              <b-col
                v-if="stop"
                class="mb-1"
              >
                <div class="truncate">
                  <span class="d-flex align-items-center text-muted text-nowrap">
                    <h2 class="mb-25 font-weight-bolder">{{ stop }}</h2>
                    <b-link
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.modal-alterar-stop
                      class="ml-1"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="18"
                      />
                    </b-link>
                  </span>
                  <span>Stop (% Saldo)</span>
                </div>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>

      </b-row>

      <b-row v-if="operacao.p">
        <b-col
          v-for="grafico in operacao.p.g"
          :key="grafico.id"
          cols="12"
        >
          <b-card-actions
            :title="grafico.nome"
            action-collapse
          >
            <div class="chart-container">
              <LWChart
                :grafico="grafico"
                :series="grafico.series"
                :series-update="grafico.series"
              />
            </div>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-overlay>

    <b-modal
      id="modal-alterar-alvo"
      ref="modal-alterar-alvo"
      cancel-variant="outline-secondary"
      ok-title="Alterar"
      cancel-title="Cancelar"
      hide-header-close
      centered
      size="sm"
      no-close-on-backdrop
      title="Alterar Alvo"
      :hide-footer="loadingOverlayAlvoStop"
      @show="showModalAlvo"
      @ok="alterarAlvoHandler"
    >
      <validation-observer ref="formAlterarAlvo">
        <b-overlay
          :show="loadingOverlayAlvoStop"
          rounded="sm"
        >
          <b-form>
            <b-form-group>
              <label for="alvoTipo">
                Tipo alvo
              </label>
              <b-form-input
                id="alvoTipo"
                :value="alvoTipo"
                disabled
              />
            </b-form-group>
            <b-form-group>
              <label for="alvoAtual">
                Alvo atual
              </label>
              <b-form-input
                id="alvoAtual"
                :value="alvo"
                disabled
              />
            </b-form-group>
            <b-form-group
              :state="novoAlvoState"
              invalid-feedback="Campo alvo obrigatório"
            >
              <label for="alvoNovo">
                Novo alvo
              </label>
              <validation-provider
                #default="{ errors }"
                name="Novo alvo"
                :rules="{ required: true, regex: /^[+]?\d+(\.\d+)?$/, min_value: 0, max_value: 1000 }"
              >
                <b-form-input
                  id="alvoNovo"
                  v-model="novoAlvo"
                  :state="novoAlvoState"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </b-overlay>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-alterar-stop"
      ref="modal-alterar-stop"
      cancel-variant="outline-secondary"
      ok-title="Alterar"
      cancel-title="Cancelar"
      hide-header-close
      centered
      size="sm"
      :hide-footer="loadingOverlayAlvoStop"
      no-close-on-backdrop
      title="Alterar Stop"
      @show="showModalStop"
      @ok="alterarStopHandler"
    >
      <validation-observer ref="formAlterarStop">
        <b-overlay
          :show="loadingOverlayAlvoStop"
          rounded="sm"
        >
          <b-form>
            <b-form-group>
              <label for="stopTipo">
                Tipo stop
              </label>
              <b-form-input
                id="stopTipo"
                :value="stopTipo"
                disabled
              />
            </b-form-group>
            <b-form-group>
              <label for="stopAtual">
                Stop atual
              </label>
              <b-form-input
                id="stopAtual"
                :value="stop"
                disabled
              />
            </b-form-group>
            <b-form-group
              :state="novoAlvoState"
              invalid-feedback="Campo stop obrigatório"
            >
              <label for="stopNovo">
                Novo Stop
              </label>
              <validation-provider
                #default="{ errors }"
                name="Novo stop"
                :rules="{ required: true, regex: /^[+]?\d+(\.\d+)?$/, min_value: 0, max_value: 1000 }"
              >
                <b-form-input
                  id="stopNovo"
                  v-model="novoStop"
                  :state="novoStopState"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </b-overlay>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable */

import {
  BCard, BTable, BSpinner, BFormCheckbox, BDropdown, BButton, BDropdownItem, BCardBody, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BRow, BCol, BCardText, BLink, BModal, VBModal, VBToggle, BOverlay, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { formatDateTimeBR, formatDateTimeUTC0 } from '@/utils/filter'
import utilsMixin from '@/mixins/utilsMixin'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import LWChart from '@/layouts/components/custom/LWChart2.vue'
import SockJS from "sockjs-client"
import Stomp from "stompjs"
import operadorStoreModule from './operadorStoreModule'

const MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_FUT_STORE_MODULE_NAME = 'monitor-operador-estrategia-instancia-view-fut'

let stompClient = null

export default {

  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BTable,
    BSpinner,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BRow,
    BCol,
    BCardText,
    LWChart,
    BCardActions,
    BButton,
    BLink,
    BModal,
    VBModal,
    VBToggle,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-toggle': VBToggle,
  },
  
  mixins: [utilsMixin],

  data() {
    return {
      required,
      loadingOverlay: true,
      loadingOverlayAlvoStop: false,
      formatDateTimeBR,
      formatDateTimeUTC0,
      saidaAutomaticaDesativado: false,
      operacao: [],
      operadorData: null,
      novoAlvo: null,
      novoAlvoState: null,
      novoStop: null,
      novoStopState: null,
      textoConnect: "Connecting ...",
    }
  },

  computed: {

    alvo() {
      return this.operacao.setup && this.operacao.setup.alvoFixo? this.operacao.setup.alvoFixo: ''
    },

    stop() {
      return this.operacao.setup && this.operacao.setup.stopFixo? this.operacao.setup.stopFixo: ''
    },

    alvoTipo() {
      return this.operacao.setup && this.operacao.setup.tipoDistanciaAlvo? this.operacao.setup.tipoDistanciaAlvo: ''
    },

    stopTipo() {
      return this.operacao.setup && this.operacao.setup.tipoDistanciaStop? this.operacao.setup.tipoDistanciaStop: ''
    },
    
  },

  watch: {
  },

  created() {
    if (!store.hasModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_FUT_STORE_MODULE_NAME)) store.registerModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_FUT_STORE_MODULE_NAME, operadorStoreModule)
    this.fetchList(this.$route.params.idOp)
  },

  beforeDestroy() {
    if (store.hasModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_FUT_STORE_MODULE_NAME)) store.unregisterModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_FUT_STORE_MODULE_NAME)
  },

  methods: {

    alterarAlvo(id, operadorData, novoAlvo){
      this.loadingOverlayAlvoStop = true
      this.$store.dispatch('monitor-operador-estrategia-instancia-view-fut/alterarAlvo', { id, operadorData, novoAlvo })
        .then(() => {
          this.$refs["modal-alterar-alvo"].hide()
          this.$swal({
            icon: 'success',
            title: 'Alterando Alvo!',
            text: 'Seu alvo está sendo alterado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }).finally(() => {
          this.loadingOverlayAlvoStop = false
        })
    },

    alterarStop(id, operadorData, novoStop){
      this.loadingOverlayAlvoStop = true
      this.$store.dispatch('monitor-operador-estrategia-instancia-view-fut/alterarStop', { id, operadorData, novoStop })
        .then(() => {
          this.$refs["modal-alterar-stop"].hide()
          this.$swal({
            icon: 'success',
            title: 'Alterando Stop!',
            text: 'Seu stop está sendo alterado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }).finally(() => {
          this.loadingOverlayAlvoStop = false
        })
    },

    alterarStopHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.formAlterarStop.validate().then(success => {
        if (success) {
          this.alterarStop(this.operacao.id, this.operadorData, this.novoStop)
        }
        this.novoStopState = success
      })
    },

    alterarAlvoHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.formAlterarAlvo.validate().then(success => {
        if (success) {
          this.alterarAlvo(this.operacao.id, this.operadorData, this.novoAlvo)
        }
        this.novoAlvoState = success
      })
    },

    showModalAlvo() {
      this.operacao.setup && this.operacao.setup.alvoFixo
      this.novoAlvo = this.alvo
      this.novoAlvoState = null
    },

    showModalStop() {
      this.operacao.setup && this.operacao.setup.stopFixo
      this.novoStop = this.stop
      this.novoStopState = null
    },

    async getOperadorContexto() {
      const response = await this.$store.dispatch('monitor-operador-estrategia-instancia-view-fut/getOperadorContexto')
      return response.data
    },

    async fetchList(id) {
      this.operadorData = await this.getOperadorContexto() // Await the Promise
      this.getOperacao(this.operadorData, id)
    },

    iniciaWs(idInstancia, contexto) {
      let socket = new SockJS(`${process.env.VUE_APP_BASE_URL}${contexto}/instancia`);
      const self = this
		  stompClient = Stomp.over(socket);
		   stompClient.connect({}, function (frame) {		 
          let isFirst = true
          stompClient.subscribe(`/info/${idInstancia}`, function(val) {	
            const jsonWs = JSON.parse(val.body)

            if (self.operacao.p.dvp) {
              self.operacao.p.dvp.pnlLiquido = self.toFixed(jsonWs.pnlLiquido, 4)
              self.operacao.p.dvp.custo = self.toFixed(jsonWs.custo, 6)

            } else if (self.operacao.p.dv) {
              self.operacao.p.dv.pnlLiquido = self.toFixed(jsonWs.pnlLiquido, 4)
              self.operacao.p.dv.custo = self.toFixed(jsonWs.custo, 6)
            }

            if (isFirst) {
              self.loadingOverlay = false
              // removendo milisegundos do time
              jsonWs.p.g.forEach(g => {
                  g.series.forEach(s => {
                    if (s.tipoSerie === 'CANDLESTICK' || s.tipoSerie === 'BAR') {
                      s.candlestickSerie.forEach(candle => {
                        candle.time =  candle.time / 1000
                      })
                    }else{
                      s.serieTemporal.forEach(serie => {
                        serie.time =  serie.time / 1000
                      })
                    }
                  })
                })
              self.operacao =  jsonWs
              isFirst = false
            }else{
              // atualização

              jsonWs.p.g.forEach(newDataG => {
                const oldDataGIndex = self.operacao.p.g.findIndex(g => g.id === newDataG.id);
                if (oldDataGIndex >= 0) {
                  const oldDataG = self.operacao.p.g[oldDataGIndex];
                  newDataG.series.forEach(newDataS => {
                    if (newDataS.tipoSerie === "CANDLESTICK" || newDataS.tipoSerie === "BAR") {
                      newDataS.candlestickSerie.forEach(candle => {
                        candle.time = candle.time / 1000
                      })
                    } else {
                      newDataS.serieTemporal.forEach(serie => {
                        serie.time = serie.time / 1000
                      })
                    }

                    const oldDataSIndex = oldDataG.series.findIndex(s => s.nome === newDataS.nome);
                    if (oldDataSIndex >= 0) {
                      oldDataG.series.splice(oldDataSIndex, 1, newDataS);
                    } else {
                      oldDataG.series.push(newDataS);
                    }
                  });
                  self.operacao.p.g.splice(oldDataGIndex, 1, oldDataG);
                } else {
                  self.operacao.p.g.push(newDataG);
                }
              });
              self.operacao.setup = jsonWs.setup
              self.operacao.p.dv =  jsonWs.p.dv
            }
          });
      });
    },

    async getOperacao(operadorData, id) {
      this.$store.dispatch('monitor-operador-estrategia-instancia-view-fut/getOperacao', { id, operadorData })
        .then(response => { 

          this.operacao = response.data

          this.operacao.p.g.forEach(g => {
            g.series.forEach(s => {
              if (s.tipoSerie === 'CANDLESTICK' || s.tipoSerie === 'BAR') {
                s.candlestickSerie.forEach(candle => {
                  candle.time =  candle.time / 1000
                })
              }else{
                s.serieTemporal.forEach(serie => {
                  serie.time =  serie.time / 1000
                })
              }
            })

          })
          
          this.iniciaWs(this.operacao.idInstancia, operadorData.contexto)

        })
        .catch(e => {
          console.log(e)
          this.textoConnect = 'Operação não encontrada ou finalizada';
        })
    },

    // Encerrar Agora
    confirmarSaidaAutomaticaDesativado(id, saidaAutomaticaDesativado) {
      this.$swal({
        title: `Tem certeza que deseja ${saidaAutomaticaDesativado? 'DESABILITAR': 'HABILITAR'} a saída automática?`,
        text: `Sua saída automática da operação será ${saidaAutomaticaDesativado? 'desabilitada': 'habilitada'}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode alterar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.alterarSaidaAutomatica(id, this.operadorData, saidaAutomaticaDesativado)
        }
      })
    },

    alterarSaidaAutomatica(id, operadorData, modo) {
      this.$store.dispatch('monitor-operador-estrategia-instancia-view-fut/alterarSaidaAutomatica', { id, operadorData, modo })
        .then(() => {
          this.operacao.saidaAutomaticaDesativado = modo
          this.$swal({
            icon: 'success',
            title: 'Alterando saída!',
            text: 'Sua saída está sendo alterada',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    // Encerrar Agora
    confirmarEncerrarAgora(id) {
      this.$swal({
        title: 'Tem certeza que deseja encerrar a posição agora?',
        text: 'Sua posição será encerrada pelo preço de mercado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode encerrar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.encerrarAgora(id, this.operadorData)
        }
      })
    },

    encerrarAgora(id, operadorData) {
      this.$store.dispatch('monitor-operador-estrategia-instancia-view-fut/encerrarOperacao', { id, operadorData })
        .then(() => {
          this.getOperacao(operadorData, id)
          this.$swal({
            icon: 'success',
            title: 'Encerrando operação!',
            text: 'Sua operação está sendo encerrada',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    formataValor(value, limit, convertUSD) {
      if (value) {
        if (convertUSD) {
          value = value.toLocaleString('pt-BR', { style: 'currency', currency: 'USD' })
        }
        if (limit) {
          value =  parseFloat(value).toFixed(limit)
        }
        return value
      }
      return '-'
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
